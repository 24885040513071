import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as redux from '../../redux/CategoryRedux'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {RootState} from '../../../../../setup'
export function Unit() {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState<any>({type: {op: '=', value: 'unit'}})
  const last_page: any = useSelector<RootState>(({category}) => category.last_page)
  const [page, setPage] = useState<any>(1)
  const [departmentList, setDepartmentList] = useState<any>([])
  const history = useHistory()
  const [categoryToDelete, setCategoryToDelete] = useState({id: null, name: '', name_ar: ''})
  const departments: any = useSelector<RootState>(({category}) => category.departmentsList)
  const domains: any = useSelector<RootState>(({category}) => category.domainsList)
  const units: any = useSelector<RootState>(({category}) => category.unitsList)
  const [category, setCategory] = useState('all')
  useEffect(() => {
    if (!departments) dispatch(redux.actions.getDepartments())
    dispatch(redux.actions.getDomains())
    if (departments) setDepartmentList(departments)
  }, [departments])
  const deleteCategory = (category: any) => {
    setCategoryToDelete(category)
  }
  useEffect(() => {
    dispatch(redux.actions.getUnits({filter: JSON.stringify(filter), page: page}))
  }, [filter, page])
  const editCategory = (category: any) => {
    history.push('/crafted/pages/categories/unit/' + category.id)
  }
  const confirmDeleteCategory = (category: any) => {
    dispatch(redux.actions.deleteCategory(category))
  }
  const handelFilterChange = (filterName: any, operand: any, filterValue: any) => {
    let newFilter = {[filterName]: {op: operand, value: filterValue}}
    if (!filterValue || filterValue == 'all') {
      setFilter((prevState: any) => {
        let temp = prevState
        delete temp[filterName]
        return {...temp}
      })
    } else {
      setFilter((prevState: any) => {
        return {...prevState, ...newFilter}
      })
    }
  }
  const handelDomainChange = (filterValue: any) => {
    setDepartmentList([])
    if (!filterValue || filterValue == 'all') setDepartmentList(departments)
    else setDepartmentList(departments.filter((el: any) => el.parent_id == filterValue))
  }
  return (
    units && (
      <>
        <div className='table-responsive'>
          {/* begin::Table */}
          <div className='row mb-10'>
            <div className='col-lg-3'>
              <select
                name='parent_id'
                className=' form-select'
                onClick={(e: any) => {
                  handelDomainChange(e.target.value)
                }}
              >
                <option value='all' style={{display: 'none'}}>
                  Select a domain
                </option>
                <option value='all'>All</option>
                {domains?.length > 0 &&
                  domains.map((site: any) => (
                    <option key={site?.id} value={site?.id}>
                      {site?.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className='col-lg-3'>
              <select
                name='parent_id'
                className=' form-select'
                onClick={(e: any) => {
                  handelFilterChange('parent_id', '=', e.target.value)
                }}
              >
                <option value='all' style={{display: 'none'}}>
                  Select a department
                </option>
                <option value='all'>All</option>
                {departmentList.length > 0 &&
                  departmentList.map((site: any) => (
                    <option key={site?.id} value={site?.id}>
                      {site?.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className='col-lg-6'>
              <a
                href='/crafted/pages/categories/unit/create'
                className='col-lg-2 btn btn-sm btn-primary'
                id='kt_toolbar_primary_button'
                style={{float: 'right', backgroundColor: '#87e8c5'}}
              >
                Create
              </a>
            </div>
          </div>
          <div>
            {/* begin::Row */}
            <div
              className='row py-10 mx-10'
              style={{backgroundColor: 'white'}}
              data-kt-control='toggle-buttons'
            >
              <input
                type='radio'
                className='btn-check'
                name='form-options'
                checked={category === 'all'}
                value='all'
                id='kt_form_options_1'
                onChange={(e) => {
                  setCategory(e.target.value)
                  handelFilterChange('status', '=', e.target.value)
                }}
              />
              <label
                className={`col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-125px ${
                  category === 'all' ? 'active' : ''
                }`}
                htmlFor='kt_form_options_1'
              >
                <span className='text-gray-800 fw-bold'>All</span>
              </label>

              <input
                type='radio'
                className='btn-check'
                name='form-options'
                value='active'
                id='kt_form_options_2'
                checked={category === 'active'}
                onChange={(e) => {
                  setCategory(e.target.value)
                  handelFilterChange('status', '=', e.target.value)
                }}
              />
              <label
                className={`col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-125px ${
                  category === 'active' ? 'active' : ''
                }`}
                htmlFor='kt_form_options_2'
              >
                <span className='text-gray-800 fw-bold'>Active</span>
              </label>

              <input
                type='radio'
                className='btn-check'
                name='form-options'
                value='pending'
                id='kt_form_options_3'
                checked={category === 'pending'}
                onChange={(e) => {
                  setCategory(e.target.value)
                  handelFilterChange('status', '=', e.target.value)
                }}
              />
              <label
                className={`col btn btn-lg btn-outline btn-bg-light btn-color-gray-600 btn-active-light-primary border-dashed border-active py-5 px-4 m-2 min-w-125px ${
                  category === 'pending' ? 'active' : ''
                }`}
                htmlFor='kt_form_options_3'
              >
                <span className='text-gray-800 fw-bold'>Pending</span>
              </label>
            </div>
            {/* end::Row */}
          </div>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Name</th>
                <th className='min-w-150px'>Arabic Name</th>
                <th className='min-w-150px'>Department</th>
                <th className='min-w-140px'>Status</th>
                <th className='min-w-140px'>User</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {units.map((unit: any, i: any) => {
                return (
                  <tr key={i}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {unit.name}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {unit.name_ar}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {unit?.parent?.name || '-'}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          {unit.status == 'active' && (
                            <span className='badge badge-light-success'>{unit.status}</span>
                          )}
                          {unit.status == 'pending' && (
                            <span className='badge badge-light-warning'>{unit.status}</span>
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {unit.user ? unit.user?.first_name + ' ' + unit.user?.last_name : '-'}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <a
                          onClick={(e) => {
                            editCategory(unit)
                          }}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </a>
                        <a
                          onClick={(e) => {
                            deleteCategory(unit)
                          }}
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_1'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          <div className='d-flex flex-stack flex-wrap pt-10' style={{float: 'right'}}>
            <ul className='pagination'>
              {last_page &&
                Array.from(Array(last_page), (_, i) => i + 1).map((number: any) => {
                  return (
                    <li  key = {number} className={`page-item ${number == page ? 'active' : ''}`}>
                      <a onClick={() => setPage(number)} className='page-link'>
                        {number}
                      </a>
                    </li>
                  )
                })}
            </ul>
          </div>
          <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Delete Unit</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <p>Are you sure you want to delete "{categoryToDelete?.name}"</p>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                    Close
                  </button>
                  <button
                    type='button'
                    data-bs-dismiss='modal'
                    onClick={(e) => {
                      confirmDeleteCategory(categoryToDelete)
                    }}
                    className='btn btn-primary'
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* end::Table */}
        </div>
      </>
    )
  )
}
