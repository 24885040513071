import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as redux from '../../redux/CompanyRedux'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import {RootState} from '../../../../../setup'
export function Role() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [roleToDelete, setRoleToDelete] = useState({id:null, name: '', name_ar: ''})
  const roles: any = useSelector<RootState>(({company}) => company.rolesList)
  useEffect(() => {
    dispatch(redux.actions.getRoles())
  }, [])
  const deleteRole = (role: any) => {
    setRoleToDelete(role)
  }
  const editRole = (role: any) => {
    history.push("/crafted/pages/company/role/edit/"+role.id)
  }
  const confirmDeleteRole = (role: any) => {
    dispatch(redux.actions.deleteRole(role))
  }
  return (
    roles &&  (
      <>
        <div className='table-responsive'>
          {/* begin::Table */}
          <a
            href='/crafted/pages/company/role/create'
            className='btn btn-sm btn-primary'
            id='kt_toolbar_primary_button'
            style={{float: 'right', backgroundColor: '#87e8c5'}}
          >
            Create
          </a>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Role</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {roles.map((role: any, i: any) => {
                return (
                  <tr key={i}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {role.name}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <a
                          onClick={(e) => {
                            editRole(role)
                          }}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </a>
                        <a
                          onClick={(e) => {
                            deleteRole(role)
                          }}
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_1'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Delete Role</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <p>Are you sure you want to delete "{roleToDelete?.name}"</p>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                    Close
                  </button>
                  <button type='button' data-bs-dismiss='modal' onClick={(e) => {
                            confirmDeleteRole(roleToDelete)
                          }} className='btn btn-primary'>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* end::Table */}
        </div>
      </>
    )
  )
}
